import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './PostsField.css';

const PostsField = ({ name, posts, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    if (!posts)
        return null;

    const options = [];
    posts.forEach(post => {
        options.push({
            key: post.id,
            text: `${Utils.localized_or_fallback(post.title, i18n.language)}`,
            value: post.id
        });
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : true;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ t('posts.choose_a_post') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ t('posts.choose_a_post') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
        />
    );
}

export default PostsField;
