import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle, CreateButton } from '../../../components';
import TasksListFilters from './TasksListFilters/TasksListFilters';
import TasksListResume from './TasksListResume/TasksListResume';
import TasksListDetails from './TasksListDetails/TasksListDetails';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { loadTasks, loadPhases, loadUsers } from '../../../redux';

import './TasksList.css';

const TasksList = ({ screeningId, userId, hideTitle }) => {
    const me                    = useSelector(state => state.auth.user);
    const { tasks }             = useSelector(state => state.tasks);
    const { users }             = useSelector(state => state.users);
    const { phases }            = useSelector(state => state.phases);
    const dispatch              = useDispatch();
    const { t }                 = useTranslation();
    const [filters, setFilters] = useState({
        view_mode: 'details',
        group_type: 'days',
        activity_id: null,
        status: null,
        user_id: userId,
        start_at_gte: moment(),
        start_at_lte: moment().add(1, 'month'),
        screening_id: screeningId,
        sector_id: null,
        needsRemoteUpdate: false
    });

    useEffect(() => {
        dispatch(loadPhases());
        dispatch(loadTasks(filters));
        if (me?.can('readOther', 'users') || me?.can('readSelf', 'users'))
            dispatch(loadUsers());
    }, []);

    useEffect(() => {
        if (filters.needsRemoteUpdate)
            dispatch(loadTasks(filters));
    }, [filters])

    useEffect(() => {
        setFilters({
            ...filters,
            screening_id: screeningId
        })
    }, [screeningId])

    const on_change_filters = (values) => {
        values.needsRemoteUpdate = filters.view_mode === values.view_mode && filters.group_type === values.group_type
        setFilters(values)
    }

    const filteredUsers = React.useMemo(() => {
        return _.uniqBy(tasks?.map(t => {
            if (t.user)
                return t.user
        }), 'id').filter(f => !!f);
    }, [tasks]);

    const filteredActivities = React.useMemo(() => {
        return _.uniqBy(tasks?.map(t => {
            return t.activity
        }), 'id');
    }, [tasks]);

    const screenings = React.useMemo(() => {
        return _.uniqBy(tasks?.map(t => {
            if (t.screening)
                return t.screening
        }), 'id').filter(s => !!s);
    }, [tasks]);

    const shifts = React.useMemo(() => {
        return _.uniqBy(tasks?.map(t => {
            if (t.shift)
                return t.shift;
        }), 'id').filter(s => !!s);
    }, [tasks]);

    const sectors = React.useMemo(() => {
        return _.uniqBy(tasks?.map(t => {
            if (t.shift?.sector)
                return t.shift.sector;
        }), 'id').filter(s => !!s);
    }, [tasks]);

    const actions =
        <CreateButton
            module="tasks"
            text="tasks.add"
            customPath={`/tasks/new/${screeningId || ""}`}
        />

    const title = <span>{t("tasks.task_plural")}</span>;

    if (!tasks || !me)
        return null;

    return (
        <div className="TasksList">
            {me &&
                <>
                    <div className="noPrint">
                        {!hideTitle && (
                            <PageTitle icon="id-badge" title={title} actions={actions} />
                        )}
                        <TasksListFilters
                            userId={userId}
                            users={filteredUsers}
                            screenings={screenings}
                            phases={phases}
                            shifts={shifts}
                            sectors={sectors}
                            activities={filteredActivities}
                            onChange={on_change_filters}
                            screening_id={screeningId}
                        />
                    </div>
                    {filters.view_mode === 'resume' &&
                        <TasksListResume tasks={tasks} filters={filters} />
                    }
                    {filters.view_mode === 'details' &&
                        <TasksListDetails
                            tasks={tasks}
                            filters={filters}
                            screenings={screenings}
                            phases={phases}
                            shifts={shifts}
                            sectors={sectors}
                            users={users ?? filteredUsers ?? []}
                            me={me}
                        />
                    }
                </>
            }
        </div>
    );
}

export default TasksList;
