import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { ButtonGroup, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import moment from 'moment';
import { TableButtons, RenounceModal } from '../../../../components';
import { Utils } from '../../../../services/';
import { assignTask, claimTask, renounceTask, changeTaskStatus } from '../../../../redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './TasksListDetails.css';

function TasksListDetails({ tasks, filters, screenings, sectors, shifts, users, me }) {
    const dispatch                                      = useDispatch();
    const { t }                                         = useTranslation();
    const [hasCopied, setHasCopied]                     = useState(false);
    const [taskToRenounce, setTaskToRenounce]           = useState(null);
    const [isOpenRenounceModal, setIsOpenRenounceModal] = useState(false);

    const showRenounceModal = (task) => {
        setIsOpenRenounceModal(true);
        setTaskToRenounce(task);
    }

    const closeRenounceModal = () => {
        setIsOpenRenounceModal(false);
        setTaskToRenounce(null);
    }

    const submitRenounceModal = (msg) => {
        dispatch(renounceTask(taskToRenounce.id, msg));
        setIsOpenRenounceModal(false);
        setTaskToRenounce(null);
    }

    const default_lang = Utils.default_lang();

    const keys_map          = {};
    const tasks_by_activity = _.sortBy(tasks, (t) => t.activity.name[default_lang]);
    const sorted_tasks      = _.sortBy(tasks_by_activity, 'start_at');
    sorted_tasks.map((t, index) => {
        t.has_started    = moment(t.start_at).isBefore(moment());
        t.is_finished    = moment(t.stop_at).isBefore(moment());
        t.is_editable    = !t.is_finished;
        t.is_assigned    = t.status === Utils.TASK_STATUS_ASSIGNED;
        t.is_claimed     = t.status === Utils.TASK_STATUS_CLAIMED;
        t.is_renounced   = t.status === Utils.TASK_STATUS_RENOUNCED;
        t.is_assignable  = t.is_editable && !t.is_claimed && !t.is_renounced;
        t.is_renouncable = !t.is_finished && t.user_id == me.id && (t.is_assigned || t.is_claimed);
        t.is_claimable   = !t.is_finished &&
            (t.status == Utils.TASK_STATUS_FREE || t.status == Utils.TASK_STATUS_RENOUNCED) && _.filter(me.activities, (a) => a.id == t.activity.id).length == 1;
        switch (filters.group_type) {
            case 'days':
                const key = moment(t.start_at).format('dddd DD MMMM');
                if (!keys_map[key])
                    keys_map[key] = [];

                keys_map[key].push(t);
                break;
            case 'screenings':
                const screening = _.find(screenings, (s) => s.id === t.screening_id);
                if (screening) {
                    const key = Utils.localized_or_fallback(screening.title, default_lang) + ' / ' + moment(screening.start_at).format('dddd DD MMMM HH:mm') + " - " + screening?.place?.name ?? "";
                    if (!keys_map[key])
                        keys_map[key] = [];

                    keys_map[key].push(t);
                }
                break;
            case 'shifts':
                const shift = _.find(shifts, (s) => s.id === t.shift_id);
                if (shift) {
                    const key = moment(shift.start_at).format('dddd DD MMMM HH:mm') + " - " + Utils.localized_or_fallback(shift.sector?.name, default_lang);
                    if (!keys_map[key])
                        keys_map[key] = [];

                    keys_map[key].push(t);
                }
                break;
        }
    });

    const textToCopy = React.useMemo(() => {
        return sorted_tasks?.map((task) => {
            const parts = [
                moment(task.start_at).format('LLL'),
                moment(task.stop_at).format('LLL'),
                task?.user?.fullname ?? "",
                task.activity ? Utils.localized_or_fallback(task.activity.name, default_lang) : "",
                task.shift?.sector ? Utils.localized_or_fallback(task.shift.sector.name, default_lang) : "",
                task.shift?.sector?.admins?.length > 0 ? `${t('tasks.admins')} ${ task.shift.sector.admins?.map(admin => admin.fullname).join(' / ') }` : ""
            ].filter(v => v?.length);
            return parts.join(' - ');
        }).join('\n')

    }, [sorted_tasks]);


    const keys = _.keys(keys_map);

    return (
        <div className="TasksListDetails">
            <RenounceModal
                task={taskToRenounce}
                isOpen={isOpenRenounceModal}
                onClose={closeRenounceModal}
                onSubmit={submitRenounceModal}
            />

            <div className="noPrint clearfix">
                <div className="pull-right">
                    <CopyToClipboard text={ textToCopy } onCopy={ () => setHasCopied(true) }>
                        <Button color="info" onClick={ () => setTimeout(() => setHasCopied(false), 1000)}>
                            <i className={`fa fa-${hasCopied ? 'check' : 'clipboard'}`} />&nbsp;
                            {t('tasks.copy_to_clipboard')}
                        </Button>
                    </CopyToClipboard>
                </div>
            </div>

            <ul className="tasks-ul">
                {keys && keys.map((k) => (
                    <li key={k}>
                        <h3>
                            {filters.group_type === 'days' &&
                                <i className="fa fa-calendar-o"></i>
                            }
                            {filters.group_type === 'screenings' &&
                                <i className="fa fa-th"></i>
                            }
                            {filters.group_type === 'shifts' &&
                                <i className="fa fa-calendar-plus-o"></i>
                            }
                            &nbsp;{k}
                        </h3>
                        <ul>
                            {keys_map[k].map((task) => (
                                <li key={task.id}>
                                    <span className="activity">
                                        {task.activity && Utils.localized_or_fallback(task.activity.name, default_lang)}
                                        {(filters.group_type !== 'shifts') && (!filters.sector_id) && (task.shift?.sector) && (
                                            <span className="sector">
                                                @{ Utils.localized_or_fallback(task.shift.sector.name, default_lang) }
                                            </span>
                                        )}
                                        {task.comment &&
                                            <i className="fa fa-comments">&nbsp;</i>
                                        }
                                    </span>
                                    <span className="when">
                                        <i className="fa fa-clock-o"></i>&nbsp;
                                        {moment(task.start_at).format('HH:mm') + ' - ' + moment(task.stop_at).format('HH:mm')}
                                    </span>
                                    {task.is_assignable && me?.can('change_status', 'tasks') ?
                                        <span className="status change">
                                            <InputGroup size="sm">
                                                <InputGroupAddon addonType="prepend" className="noPrint">{t('tasks.status')}</InputGroupAddon>
                                                <Input type="select" className="custom-select" value={task.status || ""} onChange={(e) => dispatch(changeTaskStatus(task.id, e.target.value))}>
                                                    <option key="0" value="">---</option>
                                                    {[Utils.TASK_STATUS_NEW, Utils.TASK_STATUS_ASSIGNED, Utils.TASK_STATUS_FREE].map((s) =>
                                                        <option key={s} value={s} >{t('tasks.status_' + s)}</option>
                                                    )}
                                                </Input>
                                            </InputGroup>
                                        </span>
                                        :
                                        <span className="status">{t('tasks.status_' + task.status)}</span>
                                    }
                                    {task.is_assignable && me?.can('assign', 'tasks') ?
                                            <span className="user assign">
                                                <InputGroup size="sm">
                                                    <InputGroupAddon addonType="prepend" className="noPrint">{t('tasks.assign')}</InputGroupAddon>
                                                    <Input type="select" className="custom-select" value={task.user_id || ""} onChange={(e) => dispatch(assignTask(task.id, e.target.value))}>
                                                        <option key="0" value="">---</option>
                                                        {users && _.filter(users, (u) => _.filter(u.activities, (a) => a.id === task.activity_id).length).sort(Utils.sortOnProperty('firstname')).map((u) =>
                                                            <option key={u.id} value={u.id}>{u.fullname}</option>
                                                        )}
                                                    </Input>
                                                </InputGroup>
                                            </span>
                                            :
                                            <span className="user">
                                                <>
                                                    <i className="fa fa-user"></i>&nbsp;
                                                    {task?.user?.fullname ?? "-"}
                                                </>
                                            </span>
                                    }
                                    {task.is_editable &&
                                        <ButtonGroup className="buttons">
                                            <TableButtons
                                                id={task.id}
                                                actions={["edit", "delete"]}
                                                module="tasks"
                                                owner={task.created_by}
                                            />
                                            {task.is_renouncable && me?.can('renounce', 'tasks') &&
                                                <Button onClick={(e) => showRenounceModal(task)} color="warning" title={t("tasks.renounce")} size="sm">
                                                    <i className="fa fa-thumbs-down"></i>&nbsp;
                                                    <span>{t("tasks.renounce")}</span>
                                                </Button>
                                            }
                                            {task.is_claimable && me?.can('claim', 'tasks') &&
                                                <Button onClick={(e) => dispatch(claimTask(task.id))} color="info" title={t("tasks.claim")} size="sm">
                                                    <i className="fa fa-hand-rock-o"></i>&nbsp;
                                                    <span>{t("tasks.claim")}</span>
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    }
                                        {(!filters.sector_id) && (task.shift?.sector?.admins?.length > 0) && (
                                        <span className="admins">
                                            { t('tasks.admins') } { task.shift.sector.admins?.map(admin => admin.fullname).join(' / ') }
                                        </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TasksListDetails;
