import React, { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';

const OverlapQuestionComponent = (props) => {
    const { input, onSignChange, questions } = props;

    useEffect(() => {
        onSignChange(input.value);
    }, [input.value]);

    const onChange = (e, data) => {
        input.onChange(data.value === 'custom' ? 'P0Y0M0DT0M0S' : data.value);
    }

    const options = (questions || []).map(v => ({
        text: v.text,
        key: v.value,
        value: v.value
    }));
    options.unshift({ text: '---', key: '---', value: null });

    return <Dropdown
        name={ input.name }
        className="form-control"
        options={ options }
        onChange={ onChange }
        search
        selection
    />;
}

export default OverlapQuestionComponent;
