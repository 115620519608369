import React, { useEffect } from 'react';
import 'react-table-6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Utils, Filters } from '../../../services';
import { ButtonGroup, Button } from "reactstrap";
import { PageTitle, Ability, Table, TableButtons } from '../../../components';
import { Application } from '../../../models';
import {
    loadApplications, loadApplicationTypes,
    acceptApplication, rejectApplication,
    loadSectors
} from '../../../redux';
import moment from 'moment';


import './ApplicationsList.css';
import user_avatar from '../../../assets/images/user_avatar.jpg';

const ApplicationsList = () => {
    const { t, i18n }  = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    const { user }                    = useSelector(state => state.auth);
    const { sectors }                 = useSelector(state => state.sectors);
    const { application_types }       = useSelector(state => state.applicationTypes);
    const { applications, isLoading } = useSelector(state => state.applications);

    useEffect(() => {
        dispatch(loadApplications());
        dispatch(loadApplicationTypes());
        dispatch(loadSectors());
    }, [dispatch]);

    const accept = id => dispatch(acceptApplication(id));
    const reject = id => dispatch(rejectApplication(id));

    const title = <span>{t("applications.applications_plural")}</span>;

    const actions = (
        <Ability can="applications:create">
            { application_types?.map(type =>
                <Button
                    to={`/public/${user?.tkt_instance.slug}/application/${type.id}`}
                    key={type.id}
                    tag={Link}
                    size="sm"
                    color="info"
                    title={t("applications.public_form")}
                    target="_blank"
                    className="ml-2"
                >
                    <i className="fa fa-plus"></i>&nbsp;
                    { Utils.localized_or_fallback(type.name, i18n.lang) }
                </Button>
            )}
        </Ability>
    );

    const applicationsToShow = applications?.map(app => ({
        ...app,
        fullname: app?.user?.fullname
    }));

    const columns = [{
        Header: t('applications.fullname'),
        accessor: "fullname",
        filterMethod: Filters.textFilterMethod('fullname'),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && (
            <div className="user_cell">
                <div className="img_wrapper">
                    <img src={row.original.user.avatar || user_avatar} />
                </div>
                <div>
                    <Link to={`/users/edit/${row.original.user.id}#team`}>
                        <span className="fullname">{row.original.user?.fullname}</span>
                        <span className="email">{row.original.user?.email}</span>
                    </Link>
                </div>
            </div>
        )
    }, {
        Header: t('applications.max_nb_hours_header'),
        accessor: "max_nb_hours",
        filterMethod: Filters.textFilterMethod("max_nb_hours"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (row.value >= 0) && (
            <h4 className="text-center w-100">
                <Badge color="dark">
                    { row.value }h / { row.original.max_nb_hours_per_day }h
                </Badge>
            </h4>
        )
    }, {
        Header: t('applications.nb_hours'),
        accessor: "nb_hours",
        filterMethod: Filters.textFilterMethod("nb_hours"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (row.value >= 0) && (
            <h4 className="text-center w-100">
                <Badge color="dark">
                    { row.value.toFixed(2) } h
                </Badge>
            </h4>
        )
    }, {
        Header: t('applications.busy_rate'),
        accessor: "busy_rate",
        filterMethod: Filters.textFilterMethod("busy_rate"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (row.value >= 0) && (
            <h4 className="text-center w-100">
                <Badge color={row.value >= 100 ? 'danger' : (row.value >= 75 ? 'warning' : (row.value > 0 ? 'success' : 'info'))}>
                    { row.value.toFixed(2) } %
                </Badge>
            </h4>
        )
    }, {
        Header: t('applications.sectors'),
        accessor: "sectors",
        filterMethod: Filters.sectorsFilterMethod,
        Filter: Filters.sectorsFilter(sectors),
        filterAll: true,
        Cell: row => row.value && (
            <div className="text-center d-flex flex-wrap">
                { row.value?.map(sector => (
                    <div key={sector.id} className="badge badge-light m-1"> {
                        sector.name[i18n.language]}
                    </div>
                ))}
            </div>
        )
    }, {
        Header: t('applications.created_at'),
        accessor: "created_at",
        filterMethod: Filters.textFilterMethod("created_at"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (
            <div className="dates">
                <span className="date">
                    <i className="fa fa-calendar"></i>&nbsp;
                    {moment(row.value).format('LLL')}
                </span>
            </div>
        )
    }, {
        Header: t('applications.status'),
        accessor: "status",
        filterMethod: Filters.textFilterMethod("status"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && (
            <div className="text-center">
                {row.value === Application.STATUS_SUBMITTED && (
                    <div className="badge badge-info py-2 px-4">
                        { t('applications.status_submitted') }
                    </div>
                )}
                {row.value === Application.STATUS_ACCEPTED && (
                    <div className="badge badge-success py-2 px-4">
                        { t('applications.status_accepted') }
                    </div>
                )}
                {row.value === Application.STATUS_REJECTED && (
                    <div className="badge badge-danger py-2 px-4">
                        { t('applications.status_rejected') }
                    </div>
                )}
            </div>
        )
    }, {
        Header: t('common.actions'),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <div>
                <ButtonGroup>
                    <Ability can="applications:read">
                        <Button tag={Link} to={`/users/edit/${row.original.user.id}#team`} color="primary" title={t("common.see")} size="sm">
                            <i className="fa fa-eye"></i>
                        </Button>
                    </Ability>
                    <Ability can="applications:delete">
                        <Button tag={Link} to={`/applications/delete/${row.value}`} color="danger" title={t("common.delete")} size="sm">
                            <i className="fa fa-trash"></i>
                        </Button>
                    </Ability>
                </ButtonGroup>
                <div className="mt-1">
                    {row.original.status === Application.STATUS_SUBMITTED && (
                        <ButtonGroup>
                            <Button color="success" onClick={() => accept(row.value)} title={t('applications.accept_this_application')} size="sm">
                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>
                            </Button>
                            <Button color="danger" onClick={() => reject(row.value)} title={t('applications.reject_this_application')} size="sm">
                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>
                            </Button>
                        </ButtonGroup>
                    )}
                    {row.original.status === Application.STATUS_ACCEPTED && (
                        <Button color="danger" onClick={() => reject(row.value)} title={t('applications.reject_this_application')} size="sm">
                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>
                        </Button>
                    )}
                    {row.original.status === Application.STATUS_REJECTED && (
                        <Button color="success" onClick={() => accept(row.value)} title={t('applications.accept_this_application')} size="sm">
                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>
                        </Button>
                    )}
                </div>
            </div>
        )
    }];

    return (
        <div className="ApplicationsList">
            <PageTitle icon="address-book-o" title={title} actions={actions} />
            <Table id="applicationsTable" data={applicationsToShow} columns={columns} />
        </div>
    );
}

export default ApplicationsList;
